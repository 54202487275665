<template>
  <div id="payeeEditOutbounds">
    <header-nav :content="`${$t('pages_main_021')} / ${ editForm.id ? $t('pages_pay_305') : $t('pages_pay_204')}`" />
    <div class="common-card-body">
      <a-steps :current="0" size="small" class="payee-edit-steps">
        <a-step :title="$t('pages_pay_014')" />
        <a-step :title="$t('pages_pay_015')" />
        <a-step :title="$t('pages_pay_016')" />
      </a-steps>
      <div v-if="editForm.id && spinLoading" class="spinning-load">
        <a-spin :tip="$t('common_text_045')" />
      </div>
      <a-form
        v-else
        ref="editRef"
        :model="editForm"
        :rules="editRules"
        class="payee-edit-form ant-round-form"
        layout="vertical"
      >
        <div class="area-title">{{ $t('pages_pay_205') }}</div>
        <div class="area-form">
          <a-form-item
            name="countryCode"
            :required="true"
            :label="$t('pages_pay_094')"
          >
            <a-select
              v-model:value="editForm.countryCode"
              allow-clear
              show-search
              option-label-prop="label"
              option-filter-prop="label"
              :disabled="disabled"
              :placeholder="$t('common_text_005') + $t('pages_pay_094')"
              @change="countryChange"
            >
              <a-select-option
                v-for="item in outboundCountries"
                :key="item.id"
                :value="item.country"
                :label="`${item.country} - ${item.countryChineseName}`"
              >
                <span>{{ item.country }} - {{ item.countryChineseName }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            v-if="editForm.countryCode"
            name="paymentCurrency"
            style="width: 300px"
            :required="true"
            :label="$t('pages_pay_198')"
          >
            <a-select
              v-model:value="editForm.paymentCurrency"
              allow-clear
              show-search
              :disabled="disabled"
              :placeholder="$t('common_text_005')"
              @change="currencyChange"
            >
              <a-select-option
                v-for="item in currencys"
                :key="item"
                :value="item"
              >
                <img :src="getCountryLogo(item)" class="select-img-country">
                <span>{{ item }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            name="bankAcctType"
            :required="true"
            :label="$t('pages_pay_089')"
            @change="getDynamicParams"
          >
            <a-radio-group
              v-model:value="editForm.bankAcctType"
              button-style="solid"
              :disabled="disabled"
              @change="payeeTypeChange"
            >
              <a-radio-button class="radio-left" value="B2C">{{ $t('pages_pay_207') }}</a-radio-button>
              <a-radio-button class="radio-right" value="B2B">{{ $t('pages_pay_206') }}</a-radio-button>
            </a-radio-group>
          </a-form-item>

        </div>

        <div v-if="editForm.paymentCurrency && paymentModeOpts.length !== 0" class="area-title">{{ $t('pages_pay_211') }}</div>
        <div v-if="editForm.paymentCurrency && paymentModeOpts.length !== 0" class="area-form">
          <a-form-item
            name="paymentMode"
            :required="true"
            :label="$t('pages_pay_078')"
          >
            <div class="settle-list" style="width: 688px" :style="{cursor: disabled ? 'not-allowed' : ''}">
              <div
                v-if="paymentModeOpts.includes('SWIFT')"
                class="settle-card"
                :class="editForm.paymentMode === 'SWIFT' ? 'active' : ''"
                :style="{pointerEvents: disabled ? 'none' : '', cursor: disabled ? 'auto' : 'pointer'}"
                @click="payCardChange('SWIFT')"
              >
                <div class="title">SWIFT</div>
                <div class="content">
                  <div>{{ $t('pages_pay_309') }}</div>
                  <div>{{ $t('pages_pay_310') }}、{{ $t('pages_pay_193') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_311') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
              <div
                v-if="paymentModeOpts.includes('LOCAL')"
                class="settle-card"
                :class="editForm.paymentMode === 'LOCAL' ? 'active' : ''"
                @click="payCardChange('LOCAL')"
              >
                <div class="title">LOCAL-bank transfer</div>
                <div class="content">
                  <div>{{ $t('pages_pay_313') }}</div>
                  <div>{{ $t('pages_pay_314') }}、{{ $t('pages_pay_193') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_315') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
              <div
                v-if="paymentModeOpts.includes('WALLET')"
                class="settle-card"
                style="margin-right: 0"
                :class="editForm.paymentMode === 'WALLET' ? 'active' : ''"
                @click="payCardChange('WALLET')"
              >
                <div class="title">LOCAL-mobile wallet</div>
                <div class="content">
                  <div>{{ $t('pages_pay_373') }}</div>
                  <div>{{ $t('pages_pay_374') }}、{{ $t('pages_pay_375') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_pay_039') }}</div>
                  <div>{{ $t('pages_pay_315') }}</div>
                  <div>{{ $t('pages_pay_312') }}</div>
                </div>
              </div>
            </div>
          </a-form-item>

          <a-skeleton v-if="formLoading" shape="square" active />

          <a-form-item
            v-for="item in singleDemoList"
            :key="item.fieldName"
            :required="item.required"
            :label="item.title"
            :name="item.fieldName"
          >
            <a-input
              v-if="item.fieldType === 1"
              v-model:value="editForm[item.fieldName]"
              :disabled="disabled"
              :placeholder="$t('common_text_006') + item.title"
            />
            <a-select
              v-if="item.fieldType === 2"
              v-model:value="editForm[item.fieldName]"
              option-filter-prop="label"
              allow-clear
              show-search
              :disabled="disabled"
              :placeholder="$t('common_text_005')"
            >
              <a-select-option
                v-for="i in item.fieldKeyList"
                :key="i.key"
                :value="i.value"
                :label="i.key + i.value"
              >
                <span>{{ i.key }}</span>
              </a-select-option>
            </a-select>
            <a-date-picker
              v-if="item.fieldType === 3"
              v-model:value="editForm[item.fieldName]"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :placeholder="$t('common_text_006') + item.title"
            />
          </a-form-item>
        </div>

        <div class="area-title">{{ $t('pages_pay_213') }}</div>
        <div class="area-form" style="width: 588px">
          <a-form-item :required="true" :label="$t('pages_pay_316')" style="margin-bottom: 0">
            <div style="display: flex">
              <a-form-item
                name="payeeCountryCode"
                style="width: 31%; overflow: auto; padding: 1px 2px"
                :required="true"
              >
                <a-select
                  v-model:value="editForm.payeeCountryCode"
                  allow-clear
                  show-search
                  option-label-prop="label"
                  option-filter-prop="label"
                  :disabled="disabled || (editForm.countryCode === 'UNITED STATES' && editForm.paymentMode === 'LOCAL')"
                  :placeholder="$t('pages_pay_317')"
                  @change="loadProvinces"
                >
                  <a-select-option
                    v-for="item in countries"
                    :key="item.id"
                    :value="item.countryCode"
                    :label="item.countryEnglishName"
                  >
                    <span>{{ item.countryEnglishName }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                name="payeeProvince"
                style="width: 31%; margin-left: 3.5%; padding: 1px 2px"
                :required="true"
              >
                <a-select
                  v-model:value="editForm.payeeProvince"
                  allow-clear
                  show-search
                  :disabled="disabled"
                  :placeholder="$t('pages_pay_318')"
                  @change="loadCities"
                >
                  <a-select-option
                    v-for="item in provinces"
                    :key="item.statesCode"
                    :value="item.statesEnglishName"
                  >
                    <span>{{ item.statesEnglishName }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                name="payeeCity"
                style="width: 31%; margin-left: 3.5%; padding: 1px 2px"
                :required="true"
              >
                <a-select
                  v-model:value="editForm.payeeCity"
                  allow-clear
                  show-search
                  :disabled="disabled"
                  :placeholder="$t('pages_pay_319')"
                >
                  <a-select-option
                    v-for="item in cities"
                    :key="item.statesCode"
                    :value="item.cityEnglishName"
                  >
                    <span>{{ item.cityEnglishName }}</span>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <a-form-item name="payeeAddress" :rules="{required: true, max: 256}">
              <a-textarea
                v-model:value="editForm.payeeAddress"
                class="input-primary"
                allow-clear
                :disabled="disabled"
                :placeholder="$t('pages_pay_320')"
              />
            </a-form-item>
          </a-form-item>

          <a-form-item
            name="payeePostalCode"
            :label="$t('pages_pay_321')"
            :rules="[{ max: 256 }, { validator: validateCode }]"
          >
            <a-input
              v-model:value="editForm.payeePostalCode"
              allow-clear
              :disabled="disabled"
              :placeholder="$t('pages_pay_322')"
            />
          </a-form-item>
        </div>

        <div v-if="!disabled" class="area-form" style="margin-top: 48px">
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px"
            :loading="loading"
            @click="handleSubmit"
          >{{ $t('common_text_002') }}</a-button>
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px; margin-left: 36px"
            ghost
            @click="handleReset"
          >{{ $t('common_text_040') }}</a-button>
        </div>
        <div v-else class="area-form" style="margin-top: 48px">
          <a-button
            type="primary"
            shape="round"
            style="min-width: 138px"
            ghost
            @click="$router.back(-1)"
          >{{ $t('common_text_003') }}</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/header-nav'
import { ref, reactive, toRefs } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _pay } from '@/api'
import { message } from 'ant-design-vue'
import { getCountryLogo } from '@/util'
import i18n from '@/locale'
export default {
  name: 'PayeeEditOutbounds',
  components: {
    'header-nav': HeaderNav
  },
  setup () {
    const router = useRouter()
    const route = useRoute()
    const editRef = ref(null)
    const state = reactive({
      loading: false,
      formLoading: false,
      spinLoading: false,
      outboundCountries: [],
      countries: [],
      currencys: [],
      provinces: [],
      cities: [],
      disabled: route.query.type && route.query.type === 'detail',
      paymentModeOpts: [],
      singleDemoList: [],
      editForm: {
        id: route.query.payeeId,
        version: '',
        countryCode: null,
        bankAcctType: '',
        paymentCurrency: null,
        paymentMode: '',
        payeeCountryCode: null,
        payeeProvince: null,
        payeeCity: null,
        payeeAddress: '',
        payeePostalCode: ''
      }
    })

    const validateCode = (rule, value) => {
      if (value.indexOf('@') !== -1) return Promise.reject(i18n.global.t('pages_pay_342'))
      return Promise.resolve()
    }

    const countryChange = async (e) => {
      countryClear()
      if (e) {
        const res = await _pay.getPayeeCurrencys({ payeeCountry: e })
        if (res.data.succ) state.currencys = res.data.data.payeeCurrencys
        else message.error(res.data.resMsg)
      }
    }

    const currencyChange = () => {
      currencyClear()
    }

    const payeeTypeChange = async (e) => {
      payeeTypeClear()
      const { countryCode, paymentCurrency } = state.editForm
      if (!countryCode || !paymentCurrency) return
      const res = await _pay.getOutPayTypeByType({
        payeeCountry: countryCode,
        payOutCurrency: paymentCurrency,
        debitCurrency: 'USD',
        paymentType: e.target.value
      })
      if (res.data.succ) {
        state.paymentModeOpts = res.data.data
        if (state.paymentModeOpts.length === 1) {
          state.editForm.paymentMode = state.paymentModeOpts[0]
          payCardChange(state.editForm.paymentMode)
        } else {
          getDynamicParams()
        }
      } else {
        message.error(res.data.resMsg)
      }
    }

    const payCardChange = (paymentMode) => {
      state.editForm.paymentMode = paymentMode
      editRef.value.validateFields('paymentMode')
      payCardClear()
      getDynamicParams()
      if (state.editForm.countryCode === 'UNITED STATES' && state.editForm.paymentMode === 'LOCAL') {
        state.editForm.payeeCountryCode = 'US'
        loadProvinces(state.editForm.payeeCountryCode)
      }
    }

    const handleSubmit = () => {
      editRef.value.validateFields()
        .then(() => {
          state.loading = true
          const data = Object.assign({}, state.editForm, {})
          if (data.id) {
            data.payeeId = data.id
            return _pay.outboundPayeeUpdate(data)
          }
          return _pay.outboundPayeeAdd(data)
        })
        .then(res => {
          state.loading = false
          if (res.data.succ) {
            message.success(state.editForm.id ? i18n.global.t('common_text_044') : i18n.global.t('common_text_043'))
            router.back(-1)
          } else {
            message.error(res.data.resMsg)
          }
        }).catch(err => {
          console.log(err)
          message.error(i18n.global.t('common_text_030'))
          state.loading = false
        })
    }

    const handleReset = () => {
      const { id } = state.editForm
      state.editForm.id = id
      state.editForm.countryCode = null
      countryClear()
      editRef.value.resetFields()
    }

    const getDynamicParams = async () => {
      const { countryCode, bankAcctType, paymentCurrency, paymentMode } = state.editForm
      if (countryCode && bankAcctType && paymentCurrency && paymentMode) {
        state.formLoading = true
        const res = await _pay.getDynamicParameter({ countryCode, bankAcctType, paymentCurrency, paymentMode })
        if (res.data.succ) {
          const { singleDemoList, version } = res.data.data
          state.editForm.version = version
          state.singleDemoList = singleDemoList
          for (const item of singleDemoList) {
            state.editForm[item.fieldName] = item.fieldValue
          }
        } else {
          message.error(res.data.resMsg)
        }
        state.formLoading = false
      }
    }

    const loadPayeeDetail = async () => {
      state.spinLoading = true
      const res = await _pay.outboundPayeeDetail({ payeeId: state.editForm.id })
      if (res.data.succ) {
        const { data } = res.data
        state.currencys = data.paymentCurrencys || []
        state.paymentModeOpts = data.paymentModes || []
        state.singleDemoList = data.singleDemoList || []
        for (const i in state.editForm) {
          if (i === 'version') state.editForm[i] = data.newVersion
          else state.editForm[i] = data[i]
        }
        for (const item of data.singleDemoList) {
          state.editForm[item.fieldName] = item.fieldValue
        }
        loadProvinces(data.payeeCountryCode, 'init')
        loadCities(data.payeeProvince, 'init')
      } else {
        message.error(res.data.resMsg)
      }
      state.spinLoading = false
    }

    const loadOutboundsCountries = async () => {
      const res = await _pay.getOutboundsCountries()
      if (res.data.succ) state.outboundCountries = res.data.data
    }

    const loadCountries = async () => {
      const res = await _pay.payeeCountry()
      if (res.data.succ) state.countries = res.data.data
    }

    const loadProvinces = async (countryCode, method) => {
      if (method !== 'init') {
        state.editForm.payeeProvince = null
        state.editForm.payeeCity = null
      }
      if (countryCode) {
        const res = await _pay.payeeProvince({ countryCode })
        if (res.data.succ) state.provinces = res.data.data
      }
    }

    const loadCities = async (statesName, method) => {
      if (method !== 'init') state.editForm.payeeCity = null
      if (statesName) {
        const res = await _pay.payeeCity({ countryCode: state.editForm.payeeCountryCode, statesName })
        if (res.data.succ) state.cities = res.data.data
      }
    }

    const countryClear = () => {
      state.currencys = []
      state.editForm.paymentCurrency = null
      currencyClear()
    }

    const currencyClear = () => {
      state.editForm.bankAcctType = ''
      payeeTypeClear()
    }

    const payeeTypeClear = () => {
      state.paymentModeOpts = []
      state.editForm.paymentMode = ''
      payCardClear()
    }

    const payCardClear = () => {
      state.singleDemoList = []
      const { id, countryCode, bankAcctType, paymentCurrency, paymentMode } = state.editForm
      state.editForm = {
        id,
        version: '',
        countryCode,
        bankAcctType,
        paymentCurrency,
        paymentMode,
        payeeCountryCode: null,
        payeeProvince: null,
        payeeCity: null,
        payeeAddress: '',
        payeePostalCode: ''
      }
    }

    const init = () => {
      loadCountries()
      loadOutboundsCountries()
      if (state.editForm.id) loadPayeeDetail()
    }

    init()

    return {
      editRef,
      getCountryLogo,
      validateCode,
      getDynamicParams,
      countryChange,
      currencyChange,
      payeeTypeChange,
      payCardChange,
      handleReset,
      handleSubmit,
      loadOutboundsCountries,
      loadCountries,
      loadProvinces,
      loadCities,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#payeeEditOutbounds {
  .common-card-body {
    padding: 36px 24px;
    .spinning-load {
      margin-top: 36px;
    }
    .payee-edit-form {
      margin-top: 36px;
      text-align: left;
      padding: 0 36px;
      .area-title {
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 6px;
        border-bottom: 1px solid #f0f0f0;
        letter-spacing: 1px;
        margin-top: 24px;
      }
      .area-form {
        width: 500px;
        padding: 0 24px;
        margin-top: 24px;
        .ant-select {
          overflow: inherit;
        }
        .radio-left, .radio-right {
          width: 150px;
          padding: 0 58px;
          height: 35px;
          line-height: 35px;
          border-radius: 25px 0 0 25px;
        }
        .radio-right {
          border-radius: 0 25px 25px 0;
        }
        .input-primary:deep(.ant-input) {
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
